var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _c("b-link", { staticClass: "brand-logo mt-4 mb-4" }, [
              _c("img", {
                attrs: { src: _vm.style.loginImage, width: "150px" },
              }),
            ]),
            _c("b-card-title", { staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("Esqueceu a senha")) + "? 🔒 "),
            ]),
            _c("b-card-text", { staticClass: "mb-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "Informe seu e-mail que enviaremos instruções para você redefinir sua senha"
                    )
                  ) +
                  " "
              ),
            ]),
            _c(
              "FormulateForm",
              {
                ref: "form",
                attrs: { name: "form" },
                on: { submit: _vm.validationForm },
              },
              [
                _c("FormulateInput", {
                  staticClass: "required",
                  attrs: {
                    id: "user-email",
                    type: "email",
                    label: _vm.$t("E-mail/Login"),
                    placeholder: _vm.$t("Nome"),
                    validation: "required",
                  },
                  model: {
                    value: _vm.userEmail,
                    callback: function ($$v) {
                      _vm.userEmail = $$v
                    },
                    expression: "userEmail",
                  },
                }),
                _vm.showMarkeplace
                  ? _c("FormulateInput", {
                      staticClass: "required",
                      attrs: {
                        id: "operator",
                        validation: "required",
                        type: "vue-select",
                        label: _vm.$t("Marketplace"),
                        options: _vm.hubs,
                        placeholder: _vm.$t("Selecione"),
                        name: "login-operator",
                      },
                      model: {
                        value: _vm.operator,
                        callback: function ($$v) {
                          _vm.operator = $$v
                        },
                        expression: "operator",
                      },
                    })
                  : _vm._e(),
                _c("e-button", {
                  attrs: {
                    variant: "primary",
                    block: "",
                    type: "submit",
                    text: _vm.$t("Enviar instruções"),
                    busy: _vm.busy,
                  },
                }),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.capsLockState,
                        expression: "capsLockState",
                      },
                    ],
                    staticClass: "text-danger",
                  },
                  [_vm._v("A tecla CapsLock está ligada!")]
                ),
              ],
              1
            ),
            _c(
              "b-card-text",
              { staticClass: "text-center mt-2" },
              [
                _c(
                  "b-link",
                  { attrs: { to: { name: "auth-login" } } },
                  [
                    _c("feather-icon", { attrs: { icon: "ChevronLeftIcon" } }),
                    _vm._v(" " + _vm._s(_vm.$t("Voltar")) + " "),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }